import { AuthorsField } from "./src/fields/authors"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import 'firebase/auth';

export const onClientEntry = () => {
  window.tinacms.fields.add({
    name: "authors",
    Component: AuthorsField,
  })
}
